.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 21px;
  justify-items: center;
  max-width: 1166px;
  margin: 50px auto 0;
}
@media (max-width: 1040px) {
  .container{
    grid-template-columns: repeat(2, 1fr);

  }
}
@media (max-width: 730px) {
  .container{
    grid-template-columns: 1fr;

  }
}