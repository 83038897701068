.container{
  display: flex;
  flex-direction: column;
  gap: 30px;
  & p {
    text-align: center;
    margin:0 ;
    font-family: 'ABeeZee';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 1230px) {
  .container{
    width: 80vw;
    & img{
      width: auto;
      height: auto;
    }
  }
}