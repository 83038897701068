.container{
  max-width: 1166px;
  & p, & span {
    font-family: 'Halant';
    margin: 0;
  }
  & p{
    font-weight: 500;
    color: #000000;
    font-size: 22px;
  }
  & span{
    display: inline-block;
    font-weight: 400;
    color:#4E4E4E;
    font-size:17px;
  }
}
@media (min-width: 1445px) {
  .container {
    margin-left: auto !important;
  }
}

@media (max-width: 1300px) {
  .container {
    margin-left: auto !important;
    width: 95%;
  }
}
