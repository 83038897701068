
.less5MinTitle{
  margin: 40px auto 0 ;
  font-family: 'ABeeZee';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
}
.threeColumnContainer{
  margin: 50px 0 56px 0;
  & .leftColumn{
    padding-left: 30px;
  }
}
.earningWillBePaid{
  margin:0 auto;
  font-family: 'ABeeZee';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
}
.cryptoCardsContainer{
  margin: 60px auto 0;
  display: flex;
  gap: 15px;
  width: fit-content;
}
.joinText{
  max-width: 1176px;
  margin: 25px auto ;
  font-family: 'Halant';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  color: #4E4E4E;
}
@media (max-width: 1230px) {
  .cryptoCardsContainer{
    flex-direction: column;
    width: 80vw;
  }
}