.container{
  display: grid;
  grid-template-rows: 520px;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
  width: 100%;
  z-index: 2;
  & *::-webkit-scrollbar {
    width: 0;
  }
  & .leftColumn{
    position: relative;
    & ul {
      width: 80%;
      padding: 43px 0 0 44px;

    }
  }
  & .midColumn{
    position: relative;
  }
  & .rightColumn{
    position: relative;
    & ul {
      width: 80%;
      padding: 43px 0 0 30px;

    }
  }
  & img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover ;
    object-position: top;
    top: 0;
    right: 0;
    z-index: 1;
  }
  & .column{
    position: relative;
    z-index: 3;
    padding: 0;
    &.withoutDisc{
      list-style: none;
    }

    &:last-child{
      display: flex;
      flex-direction: column;
      gap: 13px;
      width: 31.6%;
    }
    & li {
      color:white;
      font-size: 17px;
      line-height: 27px;
    }
  }
}

@media (max-width: 1450px) {
  .container {
    & .leftColumn {
      & ul {
        padding: 21px 0 0 22px;

      }
    }
    & .rightColumn{
      & ul {
        padding: 21px 0 0 30px;
      }
    }
  }
}
@media (max-width: 1400px) {
  .container {
    & .leftColumn {
      & ul {
        padding: 0 0 0 22px;

      }
    }
  }
}
@media (max-width: 1325px) {
  .container{
    width: 90%;
    grid-template-columns: 1fr;
    height: fit-content !important;
    margin: 32px auto !important;
    & .leftColumn{
      position: relative;
      min-height: 520px;
      & ul {
        width: 95%;
        padding: 0 20px;
      }
    }
    & .midColumn{
      min-height:520px;
    }
    & .rightColumn{
      position: relative;
      & ul {
        padding: 20px 0 0 30px;

      }
    }
  }
}
@media (max-width: 450px) {
  .container{
    overflow: hidden;
    & .leftColumn{
      position: relative;
      & ul {
        height: 500px;
        overflow-y: auto;
        width: 90%;
        padding: 0 20px;
      }
    }
    & .midColumn{
      min-height:520px;
    }
    & .rightColumn{
      position: relative;
      & ul {
        padding: 20px  20px 0 30px ;
        height: 500px;
        overflow-y: auto;

      }
    }
  }
}