.container{
  display: flex;
  height: 266px;
  background: #666666;
  padding: 60px 0 0 37px;
  & .socialsContainer{
    display: flex;
    gap: 8px;
    margin: 8px 67px 0 53px;
  }
  & .titleContainer{
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 25px;
    & a,p {
      font-family: 'Roboto';
      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      color: white;
    }
    & .linksContainer{
      padding-left: 7px;
      display: flex;
      gap: 23px;
      & a{
        text-decoration: unset;
        font-weight: 600;
      }
    }
    & p{
      font-weight: 500;
      margin: 0;
      letter-spacing: 0.6px;
      &:last-child{
        padding-left: 5px;
        letter-spacing: 0.9px;
      }
    }
  }
}
@media (max-width: 880px) {
  .container{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px ;
  }
}