.container{
  margin: 10px auto 0;
  max-width: 1176px;
  .title{
    margin: 0;
    font-family: 'ABeeZee';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;

    color: #000000;
  }
  ul{
    list-style: none;
    padding-left: 20px;
    li{
      font-family: 'Halant';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 27px;
      color: #4E4E4E;
      position: relative;
      &:before{
        content: ' ';
        position: absolute;
        top: 10px;
        left: -15px;
        border-radius: 50%;
        background: #4E4E4E;
        width: 2px;
        height: 2px;
      }
    }
  }

}