.twoColumnPicture {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1920px;
  width: 100%;
  background-image: url('../../assets/threeColumnImage/rightSideSnapEdit.png'),
    url('../../assets/threeColumnImage/person.png');
  background-position: left top, right top;
  background-repeat: no-repeat, no-repeat;
  background-size: 50%, 50%;
  display: flex;
  justify-content: center;
  margin: 30px auto;

  & *::-webkit-scrollbar {
    width: 0;
  }

  & ul {
    max-width: 1200px;
    width: 100%;
    color: white;
    position: relative;
    z-index: 100;

    & li {
      font-family: 'Halant';
      font-weight: 500;
      font-size: 19px;
      line-height: 39px;
    }
  }
}
@media (max-width: 1000px) {
  .twoColumnPicture {
    grid-template-columns: 1fr;
    & ul {
      left: 0;
    }

    background-image: url('../../assets/threeColumnImage/rightSideSnapEdit.png');
    background-size: cover;
  }
}
