.secondEntryTitle{
  font-family: 'Halant';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  color: #4E4E4E;
  margin: 25px auto 0;
  max-width: 1166px;
}
.notInterestedText{
  margin: 30px auto;
  font-weight: 600;
  color: #4E4E4E;
  font-size: 16px;
  line-height: 27px;
  max-width: 1166px;
}