@media (max-width:600px) {
  .container{
    flex-direction: column;
    height: fit-content !important;
  }
  .headerMenu{
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 20px;
  }
}