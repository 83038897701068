.container {
  display: grid;
  gap: 20px;
  max-width: 1200px;
}
@media (max-width: 675px) {
  .container {
    grid-template-columns: 1fr ;
    align-items: center;
  }
}
@media (max-width: 730px) {
  .container{
    margin: 0 10px;
  }

}