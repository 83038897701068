.container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.root {
    display: inline-flex;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #FFA000;
    border-radius: 4px;
    background-color: #FFF3E0;
    color: #E65100;
}