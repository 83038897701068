@import url('https://fonts.googleapis.com/css2?family=Halant:wght@400;500;700&family=Mogra&display=swap&family=Big+Shoulders+Display:wght@700&display=swap&family=ABeeZee&display=swap');
html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}

#root > div {
    flex-shrink: 0;
}

.layout-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.gradientBg > div {
    position: relative;
    z-index: 5;
}
/*.gradientBg:before {*/
/*    content: '';*/
/*    position: absolute;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.58) 50%);*/
/*    background: -o-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.58) 50%);*/
/*    background: -moz-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.68) 50%);*/
/*    background: linear-gradient(rgba(0, 0, 0, 0), rgba(12, 12, 12, 0.58) 50%);*/
/*    z-index: 1;*/
/*}*/
