.container{
  background: url("../../assets/banner.png") no-repeat;
  background-size: cover ;
  width: 100%;
  display: flex;
  gap: 10px;
  height: 350px;
  & * {
    margin: 0;
    padding: 0;
  }
  & .content{
    margin: 0 auto ;
    justify-content: center;
    width: fit-content;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.alignLeft{
      align-items: start !important;
    }
    & h3, & p {
      font-family: 'Big Shoulders Display';
      color: white;
      width: fit-content;
    }
    & h3{
      font-weight: 700;
      font-size: 85px;
      line-height: 102px;
      text-transform: uppercase;
      white-space: pre;
    }
    & p {
      font-weight: 600;
      font-size: 32px;
      text-align: center;
    }
  }
}
@media (max-width: 600px) {
  .container{
    width: 100%;
    & .content{
      max-width: 90%;
      &.alignLeft{
        align-items: center !important;
      }
      & h3, &p {
        text-align: center;
      }
      & h3{
        font-weight: 700;
        font-size: 60px;
        line-height: 50px;
        text-transform: uppercase;
        white-space: normal;
      }
      & p {
        font-weight: 600;
        font-size: 25px;
        text-align: center;
      }
    }
  }
}